@import "npm:normalize.css/normalize.css";
@import url("https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=New+Amsterdam&display=swap");
@mixin new-amsterdam-regular {
  font-family: "New Amsterdam", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "Fira Sans", sans-serif;
  background: #fbfbff;
  font-size: calc(16px + 0.5vw);
  color: #646581;
  line-height: 1.5;
  section {
    max-width: 800px;
    margin: 4rem auto;
    &#intro {
      margin: 4em auto;
    }

    &#scroll_lock_section {
      position: relative;
      //   height: 300vh;
    }
  }
}

h1 {
  text-align: center;
  font-size: calc(28px + 2vw);
  color: #5f5980;
}

video {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: block;
  //   position: absolute;
  mask-image: radial-gradient(
    ellipse 50% 50% at 50% 50%,
    black 40%,
    transparent 100%
  );
}

.loadingSpinner {
  &.isLoaded {
    display: none;
  }
}
